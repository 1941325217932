import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/leads",
        name: "leads",
        component: () => import("@/views/LeadList.vue"),
      },
      {
        path: "/postback",
        name: "postback",
        component: () => import("@/views/PostbackForm.vue"),
      },
      {
        path: "/sales",
        name: "sales",
        component: () => import("@/views/LeadSearch.vue"),
      },
      {
        path: "/payouts",
        name: "payouts",
        component: () => import("@/views/Payouts.vue"),
      },
      {
        path: "/revision",
        name: "revision",
        component: () => import("@/views/Revision.vue"),
      },
      {
        path: "/compensations",
        name: "compensations",
        component: () => import("@/views/Compensations.vue"),
        children: [
          {
            path: "",
            name: "compensation-list",
            component: () => import("@/views/CompensationList.vue"),
          },
          {
            path: "new",
            name: "compensation-new",
            component: () => import("@/views/CompensationForm.vue"),
            props: {
              mode: "new",
            },
          },
          {
            path: ":id",
            name: "compensation-view",
            component: () => import("@/views/CompensationView.vue"),
          },
          {
            path: ":id/edit",
            name: "compensation-edit",
            component: () => import("@/views/OfferForm.vue"),
            props: {
              mode: "edit",
            },
          },
        ],
      },
      {
        path: "/requests",
        name: "requests",
        component: () => import("@/views/Requests.vue"),
      },
      {
        path: "/affiliates",
        name: "affiliates",
        component: () => import("@/views/Affiliates.vue"),
        children: [
          {
            path: "",
            name: "affiliate-list",
            component: () => import("@/views/AffiliateList.vue"),
          },
        ]
      },
      {
        path: "/offers",
        name: "offers",
        component: () => import("@/views/Offers.vue"),
        children: [
          {
            path: "",
            name: "offer-list",
            component: () => import("@/views/OfferList.vue"),
          },
          {
            path: "new",
            name: "offer-new",
            component: () => import("@/views/OfferForm.vue"),
            props: {
              mode: "new",
            },
          },
          {
            path: ":id",
            name: "offer-view",
            component: () => import("@/views/OfferView.vue"),
          },
          {
            path: ":id/edit",
            name: "offer-edit",
            component: () => import("@/views/OfferForm.vue"),
            props: {
              mode: "edit",
            },
          },
        ],
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/Users.vue"),
        children: [
          {
            path: "",
            name: "user-list",
            component: () => import("@/views/UserList.vue"),
          },
          {
            path: "new",
            name: "user-new",
            component: () => import("@/views/UserForm.vue"),
            props: {
              mode: "new",
            },
          },
          {
            path: ":id",
            name: "user-view",
            component: () => import("@/views/UserForm.vue"),
            props: {
              mode: "edit",
            },
          },
          {
            path: ":id/edit",
            name: "user-edit",
            component: () => import("@/views/UserForm.vue"),
            props: {
              mode: "edit",
            },
          },
        ],
      },
      {
        path: "/roles",
        name: "roles",
        component: () => import("@/views/Roles.vue"),
        children: [
          {
            path: "",
            name: "role-list",
            component: () => import("@/views/RoleList.vue"),
          },
          {
            path: "new",
            name: "role-new",
            component: () => import("@/views/RoleForm.vue"),
            props: {
              mode: "new",
            },
          },
          {
            path: ":id",
            name: "role-view",
            component: () => import("@/views/RoleForm.vue"),
            props: {
              mode: "edit",
            },
          },
          {
            path: ":id/edit",
            name: "role-edit",
            component: () => import("@/views/RoleForm.vue"),
            props: {
              mode: "edit",
            },
          },
        ],
      },
      {
        path: "/stats",
        name: "stats",
        component: () => import("@/views/LeadReport.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/403",
    name: "403",
    component: () => import("@/views/crafted/authentication/Error403.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
